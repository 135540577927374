import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService, Connection, ConnectionType, RegisterResponse } from '@shared/auth';
import { AuthActions } from '@shared/auth/store';
import { SocialAuthData } from '@shared/firebase-auth/models';
import { PhoneVerificationActions } from '@shared/phone-verification';
import { AppState } from '@shared/store';
import {FormGroupState, SetValueAction} from 'ngrx-forms';
import { Observable } from 'rxjs';
import { RegisterForm } from './forms';
import { PublicRegisterFormActions, PublicRegisterFormSelectors } from './store';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import { ActivatedRoute, Router } from '@angular/router';
import {PublicServicesServicePageActions} from "@app/public/services/service/shared/store";
import {SwitchOption} from "@shared/switch";
import {UserType} from "@shared/user-type-modal";

@Injectable({
  providedIn: "root"
})
export class PublicRegisterFormFacade {
  public get isSubmitting$(): Observable<boolean> {
    return this.store.select(PublicRegisterFormSelectors.isSubmitting);
  }

  public get formState$(): Observable<FormGroupState<RegisterForm>> {
    return this.store.select(PublicRegisterFormSelectors.formState);
  }

  public get currentConnection$(): Observable<Connection> {
    return this.store.select(PublicRegisterFormSelectors.currentConnection);
  }

  public get isEmailValidating$(): Observable<boolean> {
    return this.store.select(PublicRegisterFormSelectors.isEmailValidating);
  }

  public get isPhoneValidating$(): Observable<boolean> {
    return this.store.select(PublicRegisterFormSelectors.isPhoneValidating);
  }

  public get checkingConnectionType$(): Observable<ConnectionType> {
    return this.store.select(PublicRegisterFormSelectors.checkingConnectionType);
  }
  public get stage$(): Observable<number> {
    return this.store.select(PublicRegisterFormSelectors.stage);
  }
  public  get acounTypeOptions(): Array<SwitchOption<UserType>> {
    return [
      new SwitchOption({ value: UserType.RESIDENTIAL, title: 'Residential' ,iconType:'residential'}),
      new SwitchOption({ value: UserType.COMMERCIAL, title: 'Commercial' ,iconType:'commercial'}),
      new SwitchOption({ value: UserType.TOURIST, title: 'Tourist', iconType:'tourist'}),
    ];
  }
  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
  public analytics: AngularFireAnalytics,
  protected router: Router,
  private route: ActivatedRoute,
) { }

public resetState(): void {
  this.store.dispatch(PublicRegisterFormActions.resetState());
  this.signOutSocialMedia();
}

public changeUserEmail(email: string): void {
  this.store.dispatch(PublicRegisterFormActions.prefillEmail({email}));
  this.store.dispatch(PublicRegisterFormActions.startAsyncEmailValidation());
}

public startPhoneVerification({ formState, onRegisterSuccess, token }: {
  formState: FormGroupState<RegisterForm>;
  onRegisterSuccess: (response: RegisterResponse) => void;
  token: string
}): void {
  if (formState.controls.email.isValid && formState.controls.phone.isValid && !formState.isValidationPending && !!token) {
  this.store.dispatch(PhoneVerificationActions.tryStartVerification({
    data: {
      phone: `+${formState.value.phoneCode}${formState.value.phone}`,
      token,
      onSuccess: () => {
        this.goNextStage(2)
      }
    }
  }));
}
}

  public startRegistration({ formState, onRegisterSuccess }: {
    formState: FormGroupState<RegisterForm>;
    onRegisterSuccess: (response: RegisterResponse) => void;
  }): void {
    this.analytics.logEvent('sign_up');
    return  this.store.dispatch(PublicRegisterFormActions.tryRegister({onRegisterSuccess}))
  }

  public goNextStage(stage:number){
      this.store.dispatch(PublicRegisterFormActions.goNextStage({stage:stage}))

  }
public signUpWithSocial(connection: ConnectionType,childPhoneComponent:any): void {
  this.store.dispatch(AuthActions.signInSocial({
    connection,
    onSuccess: (data) => this.store.dispatch(PublicRegisterFormActions.checkSocialToken({
        token: data.token,
        connectionType: connection,
        onSuccess: () => {
          this.analytics.logEvent('sign_up');
          this.goNextStage(1)
          childPhoneComponent.inputInitializedForBack()
          return this.handleSuccessSocialSignIn({ data, connection })
        },
        onFailure: () => this.handleFailureSocialSignIn({ data, connection })
    })),
}));
}

public signOutSocialMedia(): void {
  this.authService.signOutSocialMedia();
}

private handleSuccessSocialSignIn({ data, connection }: {
  data: SocialAuthData;
  connection: ConnectionType;
}): void {
  this.store.dispatch(PublicRegisterFormActions.signInSocialSuccess({
    connection: new Connection({ type: connection, token: data.token }),
    formValue: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone
    }
  }));
}

private handleFailureSocialSignIn({ data, connection }: {
  data: SocialAuthData;
  connection: ConnectionType;
}): void {
  this.store.dispatch(PublicRegisterFormActions.tryLogin({
    connection: new Connection(
      { type: connection, token: data.token, email: data.email}
    ),
    onLoginSuccess: () => {
      if (this.router.url.includes('/register')) {
        this.handleSuccessLogin()
      } else {
        this.store.dispatch(PublicServicesServicePageActions.handleSuccessfulAuth());
      }
    },
    email: data?.email ? data.email : null
  }))
}

public prefillRegistrationData(data: { user: SocialAuthData, connection: Connection }): void {
  this.store.dispatch(PublicRegisterFormActions.prefillRegistrationData({ data }))
}

public handleSuccessLogin(): void {
  const redirectRoute = this.route.snapshot.queryParamMap.get('redirectRoute');
  this.authService.loginRoureAction(redirectRoute);
}
}
