
import { Action, createReducer, on } from '@ngrx/store';
import { birthdateValidator } from '@shared/birthdate-validator';
import { passwordValidator } from '@shared/password-validator';
import { createPhoneValidator } from '@shared/phone-validator';
import { ClearAsyncErrorAction, markAsTouched, onNgrxForms, onNgrxFormsAction, SetAsyncErrorAction, setValue, updateGroup, validate, wrapReducerWithFormStateUpdate } from 'ngrx-forms';
import { email, equalTo, required } from 'ngrx-forms/validation';
import { RegisterForm } from '../forms';
import { PublicRegisterFormActions } from './actions';
import { PublicRegisterFormState } from './state';
import {createNoSpaceValidator} from "@shared/name-validator";

const initialState = new PublicRegisterFormState();

const reducer = wrapReducerWithFormStateUpdate(
  createReducer(
    initialState,
    on(PublicRegisterFormActions.resetState, () => initialState),
    on(PublicRegisterFormActions.tryRegister, (state) => ({
      ...state,
      isSubmitting: true
    })),
    on(
      PublicRegisterFormActions.registerSuccess,
      PublicRegisterFormActions.registerFailure,
      (state) => ({
        ...state,
        isSubmitting: false
      })
    ),
    on(PublicRegisterFormActions.signInSocialSuccess, (state, { connection, formValue }) => ({
      ...state,
      currentConnection: connection,
      formState: updateGroup<RegisterForm>(initialState.formState, {
        firstName: (controlState) => markAsTouched(setValue(formValue.firstName)(controlState)),
        lastName: (controlState) => markAsTouched(setValue(formValue.lastName)(controlState)),
        email: (controlState) => markAsTouched(setValue(formValue.email)(controlState)),
        phone: (controlState) => markAsTouched(setValue(formValue.phone)(controlState)),
        password: setValue(''),
        confirmPassword: setValue(''),
      })
    })),

    on(PublicRegisterFormActions.prefillEmail, (state, { email }) => ({
      ...state,
      formState: updateGroup<RegisterForm>(initialState.formState, {
        firstName: setValue(''),
        lastName: setValue(''),
        email: (controlState) => markAsTouched(setValue(email)(controlState)),
        phone: setValue(''),
        password: setValue(''),
        confirmPassword: setValue(''),
      })
    })),
    on(PublicRegisterFormActions.prefillRegistrationData, (state, { data }) => ({
      ...state,
      currentConnection: data.connection,
      formState: updateGroup<RegisterForm>(initialState.formState, {
        firstName: (controlState) => markAsTouched(setValue(data.user.firstName)(controlState)),
        lastName: (controlState) => markAsTouched(setValue(data.user.lastName)(controlState)),
        email: (controlState) => markAsTouched(setValue(data.user.email)(controlState)),
        phone: (controlState) => markAsTouched(setValue(data.user.phone)(controlState)),
        password: setValue(''),
        confirmPassword: setValue('')
      })
    })),
    on(PublicRegisterFormActions.checkSocialToken, (state, { connectionType }) => ({
      ...state,
      checkingConnectionType: connectionType
    })),

    on(PublicRegisterFormActions.checkSocialTokenComplete, (state) => ({
      ...state,
      checkingConnectionType: null
    })),
    on(PublicRegisterFormActions.goNextStage, (state,{stage}) => ({
      ...state,
      stage: stage,
      formState: updateGroup<RegisterForm>(initialState.formState, {
        phone: setValue(state.formState.controls.phone.value),
        email: setValue(state.formState.controls.email.value),
        phoneCode: setValue(state.formState.controls.phoneCode.value),
        firstName: setValue(state.formState.controls.firstName.value),
        lastName: setValue(state.formState.controls.lastName.value),
        password: setValue(state.formState.controls.password.value),
        confirmPassword: setValue(state.formState.controls.confirmPassword.value),
        account_type: setValue(state.formState.controls.account_type.value),
        gender: setValue(state.formState.controls.gender.value),
        genderOther: setValue(state.formState.controls.genderOther.value),
        birthdate: setValue(state.formState.controls.birthdate.value),
      }
     ),
    })),
    onNgrxForms(),
    on(PublicRegisterFormActions.asyncEmailValidationStarted, (state) => ({
      ...state,
      isEmailValidating: true
    })),
    on(PublicRegisterFormActions.asyncPhoneValidationStarted, (state) => ({
      ...state,
      isPhoneValidating: true
    })),
    onNgrxFormsAction(SetAsyncErrorAction, (state, { controlId }) => {
      if (controlId === 'RegisterForm.email') {
        return { ...state, isEmailValidating: false };
      }
      if (controlId === 'RegisterForm.phone') {
        return { ...state, isPhoneValidating: false };
      }

      return state;
    }),
    onNgrxFormsAction(ClearAsyncErrorAction, (state, { controlId }) => {
      if (controlId === 'RegisterForm.email') {
        return { ...state, isEmailValidating: false };
      }
      if (controlId === 'RegisterForm.phone') {
        return { ...state, isPhoneValidating: false };
      }

      return state;
    })
  ),
  (state: PublicRegisterFormState) => state.formState,
  (formState, state) => updateGroup<RegisterForm>(formState, {
    firstName: validate<string>(required,createNoSpaceValidator(formState.value.firstName)),
    lastName: validate<string>(required,createNoSpaceValidator(formState.value.lastName)),
    email: validate<string>(required, email),
    phone: validate<string>(required, createPhoneValidator(formState.value.phoneCode)),
    password: validate<string>((state.currentConnection) ? () => ({}) : passwordValidator,createNoSpaceValidator(formState.value.password)),
    confirmPassword: validate<string>(equalTo(formState.value.password)),
    birthdate: validate<string>(birthdateValidator),
    account_type: validate<string>(required)
  })
);

export const publicRegisterFormReducer = (
  state: PublicRegisterFormState | undefined,
  action: Action
): PublicRegisterFormState => reducer(state, action);
