import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {AuthResponse, Connection, ConnectionType, RegisterResponse} from '@shared/auth';
import { RegisterForm } from '../forms';
import { SocialAuthData } from '@shared/firebase-auth/models';

export class PublicRegisterFormActions {
  public static resetState = createAction(
    '[PublicRegisterForm] Reset State'
  );

  public static tryRegister = createAction(
    '[PublicRegisterForm] Try Register',
    props<{ onRegisterSuccess: (response: RegisterResponse) => void }>()
  );

  public static registerSuccess = createAction(
    '[PublicRegisterForm] Register Success'
  );

  public static prefillEmail = createAction(
    '[PublicRegisterForm] Prefill User Email',
    props<{ email: string }>()
  );

  public static registerFailure = createAction(
    '[PublicRegisterForm] Register Failure',
    props<{ response: HttpErrorResponse }>()
  );

  public static startAsyncEmailValidation = createAction(
    '[PublicRegisterForm] Start Async Email Validation'
  );

  public static asyncEmailValidationStarted = createAction(
    '[PublicRegisterForm] Async Email Validation Started'
  );

  public static startAsyncPhoneValidation = createAction(
    '[PublicRegisterForm] Start Async Phone Validation'
  );

  public static asyncPhoneValidationStarted = createAction(
    '[PublicRegisterForm] Async Phone Validation Started'
  );

  public static signInSocialSuccess = createAction(
    '[PublicRegisterForm] Sign In Social Success',
    props<{ connection: Connection; formValue: Partial<RegisterForm> }>()
  );

  public static checkSocialToken = createAction(
    '[PublicRegisterForm] Check Social Token',
    props<{ token: string; connectionType: ConnectionType; onSuccess: () => void, onFailure: () => void }>()
  );

  public static checkSocialTokenComplete = createAction(
    '[PublicRegisterForm] Check Social Token Complete'
  );

  public static prefillRegistrationData = createAction(
    '[PublicRegisterForm] Prefill Registration Data',
    props<{ data: { user: SocialAuthData, connection: Connection } }>()
  )

  public static goNextStage = createAction(
    '[PublicRegisterForm] Go Next Stage',
    props<{stage:number }>()
  )

  public static tryLogin = createAction(
    '[PublicRegisterForm] Try login',
    props<{
      connection?: Connection;
      onLoginSuccess: (response: AuthResponse) => void;
      email?: string | null
    }>()
  );

  public static loginSuccess = createAction(
    '[PublicRegisterForm] Login success'
  );

  public static loginFailure = createAction(
    '[PublicRegisterForm] Login failure',
    props<{ response: HttpErrorResponse }>()
  );
}
